import React from 'react'
import { LeafGreen } from '../assets/img'
import { Instagram, Facebook } from '../assets/svg'
import * as label from '../constants/ui.labels.constants'

export const Footer = () => {
  return (
    <>
      <div className='footer'>
        {/* <div className='footer--division'></div> */}
        <div className='footer__content'>
          <div className='footer__content--social-media'>
            <div className='footer__content--social-media--follow'>{label.LABELS.FOLLOW_WORK}</div>
            <div className='footer__content--social-media--fb'>
              <a href='https://m.facebook.com/sarafigphoto/' target='_blank' rel='noreferrer' style={{ cursor: 'pointer' }}>
                <img src={Facebook} alt='Sara Fig - Portugal Wedding Photography' />
              </a>
            </div>
            <div className='footer__content--social-media--instagram'>
              <a href='https://www.instagram.com/sarafigphoto/' target='_blank' rel='noreferrer' style={{ cursor: 'pointer' }}>
                <img src={Instagram} alt='Sara Fig - Portugal Wedding Photography' />
              </a>
            </div>
          </div>
          <a className='footer__content--hover' href='https://www.instagram.com/sarafigphoto/' target='_blank' rel='noreferrer'>
            <div className='footer__content--hover--follow'>{label.LABELS.INSTA_HANDLE}</div>

            <div className='footer__content--instagram-show'>
              <div className='footer__content--instagram-show--1'> </div>
              <div className='footer__content--instagram-show--2'></div>
              <div className='footer__content--instagram-show--3'></div>
              <div className='footer__content--instagram-show--4'></div>
              <div className='footer__content--instagram-show--5'></div>
            </div>
          </a>
          <div className='footer__content--menu'>
            <div className='footer__content--menu__nav'>
              <a className='footer__content--menu__nav--item' href={label.NAVIGATION_PATH.HOME}>
                {label.NAVBAR.HOME}
              </a>
              <a className='footer__content--menu__nav--item' href={label.NAVIGATION_PATH.SERVICES}>
                {label.NAVBAR.SERVICES}
              </a>
              <a className='footer__content--menu__nav--item' href={label.NAVIGATION_PATH.CONTACT}>
                {label.NAVBAR.CONTACT}
              </a>
            </div>
            <img className='footer__content--menu--image' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
            <div className='footer__content--menu--copy'>{label.LABELS.COPYRIGHT} </div>
          </div>
        </div>
      </div>
    </>
  )
}
