import React, { useState } from 'react'
import { AngleUp } from '../assets/svg'

export const ReturnToTop = () => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) setVisible(true)
    else if (scrolled <= 300) setVisible(false)
  }

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  window.addEventListener('scroll', toggleVisible)

  return (
    <>
      <button onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }} id='return-to-top' title='Go to top'>
        <img src={AngleUp} alt='Go to top' />
      </button>
    </>
  )
}
