import React from 'react'
import { Divider } from '../components/Divider'
import * as label from '../constants/ui.labels.constants'

export const ProcessMobile = () => {
  return (
    <div className='process-show-mobile'>
      <div className='process_mobile'>
        <div className='process_mobile__title'>PHOTOSHOOT PROCESS</div>

        <div className='process_mobile__number'>
          <div className='process_mobile__number--item'>01</div>
          <Divider />
        </div>
        <div className='process_mobile__box'>
          <div className='process_mobile__box--item'>
            <div className='process_mobile__subtitle'>The Planning</div>
            <div className='process_mobile__description'>{label.PROCESS.PLANNING} </div>
          </div>
        </div>
        <div className='process_mobile__number'>
          <div className='process_mobile__number--item'>02</div>
          <Divider />
        </div>
        <div className='process_mobile__box'>
          <div className='process_mobile__box--item'>
            <div className='process_mobile__subtitle'>Photoshoot Day</div>
            <div className='process_mobile__description'>{label.PROCESS.SHOOT_DAY} </div>
          </div>
        </div>
        <div className='process_mobile__number'>
          <div className='process_mobile__number--item'>03</div>
          <Divider />
        </div>

        <div className='process_mobile__box'>
          <div className='process_mobile__box--item'>
            <div className='process_mobile__subtitle'>Editing Process</div>
            <div className='process_mobile__description'>{label.PROCESS.EDITING} </div>
          </div>
        </div>
      </div>
    </div>
  )
}
