import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Logo } from '../assets/img'
import * as label from '../constants/ui.labels.constants'
import { NavbarDropdown } from '../components/NavbarDropdown'
import { Burger } from './Burger'

export const Navbar = () => {
  const [dropdown, setDropdown] = useState(false)
  const onMouseEnter = () => setDropdown(true)
  const onMouseLeave = () => setDropdown(false)
  const [url, setUrl] = useState(null)

  const location = useLocation()
  useEffect(() => {
    setUrl(location.pathname)
  }, [location])

  const activeClass = 'navbar__options-item navbar__options-item--margin-right navbar--active'
  const inactiveClass = 'navbar__options-item navbar__options-item--margin-right navbar--inactive'
  const isActive = ({ isActive }) => (isActive ? activeClass : inactiveClass)

  const isContactDropdownItem = url === '/faq' || url === '/testimonials' ? activeClass : inactiveClass
  return (
    <div className='navbar'>
      <NavLink to={label.NAVIGATION_PATH.HOME}>
        <div className='navbar__logo--fill'>
          <img src={Logo} alt='Sara Fig - Portugal Wedding Photography' className='navbar__logo--image' />
        </div>
      </NavLink>

      <ul className='navbar__options'>
        <li>
          <NavLink className={isActive} to={label.NAVIGATION_PATH.HOME} end>
            {label.NAVBAR.HOME}
          </NavLink>
        </li>
        <li>
          <NavLink className={isActive} to={label.NAVIGATION_PATH.ABOUT}>
            {label.NAVBAR.ABOUT}
          </NavLink>
        </li>
        <li>
          <NavLink className={isActive} to={label.NAVIGATION_PATH.PORTFOLIO}>
            {label.NAVBAR.PORTFOLIO}
          </NavLink>
        </li>
        <li>
          <NavLink className={isActive} to={label.NAVIGATION_PATH.SERVICES}>
            {label.NAVBAR.SERVICES}
          </NavLink>
        </li>
        <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Link className={isContactDropdownItem}>{label.NAVBAR.INFO}</Link>
          {dropdown && <NavbarDropdown isActive={isActive} />}
        </li>
        <li>
          <NavLink className={isActive} to={label.NAVIGATION_PATH.CONTACT}>
            {label.NAVBAR.CONTACT}
          </NavLink>
        </li>
      </ul>
      <div className='navbar__burger'>
        <Burger />
      </div>
    </div>
  )
}
