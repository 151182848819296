import React from 'react'
import {
  ServicesWedding1,
  ServicesWedding2,
  ServicesWedding3,
  ServicesWedding4,
  ServicesWedding5,
  ServicesWedding6,
  ServicesWedding7,
  ServicesWedding8,
  ServicesWedding9,
  ServicesEngagements1,
  ServicesEngagements2,
  ServicesEngagements3,
  ServicesEngagements4,
  ServicesEngagements5,
  ServicesFamily1,
  ServicesFamily2,
  ServicesFamily3,
  ServicesFamily4,
  ServicesFamily5,
} from '../assets/img'
import { Slideshow } from '../components/SlideShow'
import { PackStructureMobile } from '../components/PackStructureMobile'
import { PricingCardMobile } from '../components/PricingCardMobile'
const imagesWedding = [
  ServicesWedding1,
  ServicesWedding2,
  ServicesWedding3,
  ServicesWedding4,
  ServicesWedding5,
  ServicesWedding6,
  ServicesWedding7,
  ServicesWedding8,
  ServicesWedding9,
]
const imagesEngagement = [ServicesEngagements1, ServicesEngagements2, ServicesEngagements3, ServicesEngagements4, ServicesEngagements5]
const imagesFamily = [ServicesFamily1, ServicesFamily2, ServicesFamily3, ServicesFamily4, ServicesFamily5]

export const ServicesMobile = (props) => {
  return (
    <div className='services-show-mobile' style={{ paddingTop: '14.5rem' }}>
      {/* Weddings */}
      <div style={{ position: 'relative' }} id='weddings' ref={(element) => (props.weddingsSectionRef.current = element)}>
        <Slideshow images={imagesWedding} title={'WEDDINGS'} />
      </div>
      <div className='services_mobile'>
        <PackStructureMobile title={'PACK INTIMIST'} photographer={1} time={4} quantity={400} pack={'int'} coverage={'coverage'} />

        <PackStructureMobile title={'PACK STANDARD'} photographer={1} time={12} quantity={800} pack={'stand'} coverage={'coverage'} />

        <PackStructureMobile title={'PACK BIG DAY'} photographer={2} time={12} quantity={1200} pack={'big'} coverage={'coverage'} />
      </div>
      <PricingCardMobile category={'Wedding report'} pricing={'900'} prints={20} includeAllPackagesInfo hasPineBox />

      {/* Engagements */}
      <div style={{ position: 'relative' }} id='engagements' ref={(element) => (props.engagementsSectionRef.current = element)}>
        <Slideshow images={imagesEngagement} title={'ENGAGEMENTS'} />
      </div>
      <div className='services_mobile'>
        <PackStructureMobile
          title={'MY VALENTINE'}
          pack={'couples'}
          message={
            'This photoshoot is very important to prepare you for the wedding day to create a greater "comfort" in front of the camera and to create bonds in the relationship between photographer and client. It also gives you more cool and casual photos of your true selfs'
          }
          text
        />

        <PackStructureMobile
          title={'THEY SAID YES!'}
          pack={'proposal'}
          message={'Capture your surprise proposal moment for your life partner'}
          text
        />
      </div>
      <PricingCardMobile
        category={'Engagement photoshoots'}
        pricing={'200'}
        photographer={1}
        time={1}
        quantity={200}
        prints={20}
        coverage={'photoshoot'}
        withPricing
        includeAllPackagesInfo
      />

      {/* Family */}
      <div style={{ position: 'relative' }} id='family' ref={(element) => (props.familySectionRef.current = element)}>
        <Slideshow images={imagesFamily} title={'FAMILY'} />
      </div>
      <div className='services_mobile'>
        <PackStructureMobile
          title={'TRIBE'}
          location={'1 '}
          time={'45'}
          quantity={'30'}
          prints={'5'}
          coverage={'edited photos'}
          pack={'tribe'}
        />
      </div>

      {/* ALTERAR FOTO */}
      <div className='services_mobile'>
        <PackStructureMobile title={'ROOTS'} location={'1 '} time={'60'} pack={'roots'} prints={'10'} coverage={'All photos edited'} />
      </div>

      <PricingCardMobile category={'Family photoshoots'} pricing={'150'} />
    </div>
  )
}
