import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItems } from '../constants/constants'
import { v4 as uuidv4 } from 'uuid'

export const NavbarDropdown = (props) => {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  return (
    <ul onClick={handleClick} className={click ? 'dropdown-content clicked' : 'dropdown-content'}>
      {MenuItems.map((item, index) => {
        return (
          <li key={uuidv4()}>
            <NavLink className={props.isActive} to={item.path} onClick={() => setClick(false)}>
              {item.title}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}
