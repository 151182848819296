import React from 'react'

export const HomeShow = () => {
  return (
    <>
      <div className='show'>
        <div className='show__first-section'></div>
        <div className='show__second-section'>
          <div className='show__second-section__part1'>THE ADVENTURE BEGINS</div>
          <div className='show__second-section__part2'>
            <div className='show__second-section__part2--title'></div>
            <div className='show__second-section__part2--image'></div>
            <div className='show__second-section__part2--image-2'></div>
          </div>
        </div>
      </div>
      <div className='show show2'>
        <div className='show__fourth-section'>
          <div className='show__fourth-section__part3--title'>STORYTELLER</div>
          <div className='show__fourth-section__part3'>
            <div className='show__fourth-section__part3--image'></div>
          </div>
        </div>
        <div className='show__third-section'></div>
      </div>
    </>
  )
}
