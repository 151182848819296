import React from 'react'
import { Link } from 'react-router-dom'
import { HomePortfolioWedding, HomePortfolioCouples, HomePortfolioFamily } from '../assets/img'
import * as label from '../constants/ui.labels.constants'

export const HomePortfolio = () => {
  return (
    <>
      <div className='portfolio'>
        <div className='portfolio__mobile'>
          <Link to={label.NAVIGATION_PATH.WEDDINGS}>
            <div className='portfolio__mobile--item'>
              <div className='portfolio__mobile--item__image--wedding'></div>
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--1'>WEDDINGS</h3>
            </div>
          </Link>

          <Link to={label.NAVIGATION_PATH.COUPLES}>
            <div className='portfolio__mobile--item'>
              <div className='portfolio__mobile--item__image--couples'></div>
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--2'>COUPLES</h3>
            </div>
          </Link>
          <Link to={label.NAVIGATION_PATH.FAMILY}>
            <div className='portfolio__mobile--item'>
              <div className='portfolio__mobile--item__image--family'></div>
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--3'>FAMILY</h3>
            </div>
          </Link>
        </div>

        <div className='portfolio__content'>
          <Link to={label.NAVIGATION_PATH.WEDDINGS}>
            <div className='portfolio__content--item'>
              <img src={HomePortfolioWedding} alt='Sara Fig - Portugal Wedding Photography' />
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--4'>WEDDINGS</h3>
            </div>
          </Link>

          <Link to={label.NAVIGATION_PATH.COUPLES}>
            <div className='portfolio__content--item'>
              <img src={HomePortfolioCouples} alt='Sara Fig - Portugal Wedding Photography' />
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--5'>COUPLES</h3>
            </div>
          </Link>
          <Link to={label.NAVIGATION_PATH.FAMILY}>
            <div className='portfolio__content--item'>
              <img src={HomePortfolioFamily} alt='Sara Fig - Portugal Wedding Photography' />
              <h3 className='portfolio__mobile--item--title portfolio__mobile--item--title--6'>FAMILY</h3>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
