import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const Slideshow = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const timeoutRef = useRef(null)
  const delay = 6000
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }
  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () => setCurrentIndex((prevIndex) => (prevIndex === props.images.length - 1 ? 0 : prevIndex + 1)),
      delay,
    )
    return () => resetTimeout()
  }, [currentIndex, delay, props.images.length])

  const goToPrevious = () => setCurrentIndex(currentIndex === 0 ? props.images.length - 1 : currentIndex - 1)

  const goToNext = () => setCurrentIndex(currentIndex === props.images.length - 1 ? 0 : currentIndex + 1)

  return (
    <div key={currentIndex}>
      {props.title ? (
        <div className='landscape__box'>
          <div className='landscape__box--title'>{props.title}</div>
        </div>
      ) : null}
      <div className='slideshow'>
        <div className='slideshow__slider' style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}>
          {props.images.map((img) => (
            <div
              className='slideshow__slider--image'
              key={uuidv4()}
              style={{
                background: `url('${img}') center center`,
                backgroundColor: '#FFFFFF',
                backgroundSize: 'cover',
              }}></div>
          ))}
        </div>

        <div className='arrows'>
          <div className='arrows--arrow' onClick={goToPrevious}>
            <div className='long-arrow-left'></div>
          </div>
          <div className='arrows--arrow' onClick={goToNext}>
            <div className='long-arrow-right'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
