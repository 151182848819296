import React from 'react'
import * as label from '../constants/ui.labels.constants'
import { LeafWhite } from '../assets/img'

export const PackStructureMobile = (props) => {
  const isTwoPhotographers =
    props.photographer === 2 ? (
      <>
        <span className='services_mobile__box--pack--list--item__number'>1</span> to
        <span className='services_mobile__box--pack--list--item__number'>2</span> Photographer(s)
      </>
    ) : (
      <>
        <span className='services_mobile__box--pack--list--item__number'>1</span> Photographer
      </>
    )

  const tribePack = () => {
    if (props.pack === 'tribe') {
      return (
        <>
          <li className='services_mobile__box--pack--list--item'>
            <span className='services_mobile__box--pack--list--item__number'>{props.location}</span>location
          </li>{' '}
          <li className='services_mobile__box--pack--list--item'>
            <span className='services_mobile__box--pack--list--item__number'>{props.time}</span> min of session
          </li>
          <li className='services_mobile__box--pack--list--item'>
            <span className='services_mobile__box--pack--list--item__number'>{props.quantity}</span> {props.coverage}
          </li>
          <li className='services_mobile__box--pack--list--item'>
            {' '}
            <span className='services_mobile__box--pack--list--item__number'>5</span> prints{' '}
            <span className='services_mobile__box--pack--list--item__number'>10x15</span>
          </li>{' '}
        </>
      )
    } else if (props.pack === 'roots') {
      return (
        <>
          <li className='services_mobile__box--pack--list--item'>
            <span className='services_mobile__box--pack--list--item__number'>{props.location}</span>location
          </li>{' '}
          <li className='services_mobile__box--pack--list--item'>
            <span className='services_mobile__box--pack--list--item__number'>{props.time}</span> min of session
          </li>
          <li className='services_mobile__box--pack--list--item'>{props.coverage}</li>
          <li className='services_mobile__box--pack--list--item'>
            {' '}
            <span className='services_mobile__box--pack--list--item__number'>10</span> prints{' '}
            <span className='services_mobile__box--pack--list--item__number'>10x15</span>
          </li>{' '}
        </>
      )
    } else return false
  }

  const hours = props.time === 1 ? 'hour ' : 'hours '

  const longText = props.text ? (
    <div className='services_mobile__box--pack--description'>{props.message}</div>
  ) : (
    <div className='services_mobile__box--pack--list'>
      <ul>
        {props.pack === 'tribe' || props.pack === 'roots' ? (
          tribePack()
        ) : (
          <>
            <li className='services_mobile__box--pack--list--item'>{isTwoPhotographers}</li>
            <li className='services_mobile__box--pack--list--item'>
              <span className='services_mobile__box--pack--list--item__number'>{props.time} </span> {hours}
              {props.coverage}
            </li>
            <li className='services_mobile__box--pack--list--item'>
              Approx <span className='services_mobile__box--pack--list--item__number'>{props.quantity} </span> High Quality images
            </li>
          </>
        )}
      </ul>
    </div>
  )

  const showContent = () => {
    switch (true) {
      case props.pack === 'int':
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
            <div className='services_mobile__box--pack--label'>{label.LABELS.INT_GUESTS}</div>
          </>
        )
      case props.pack === 'stand':
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
            <div className='services_mobile__box--pack--label services_mobile__box--pack--label--dark'>
              <img
                className='services_mobile__box--pack--label--vector'
                src={LeafWhite}
                alt='Sara Fig - Portugal Wedding Photography'
                style={{ transform: 'rotate(45deg)' }}
              />
              {label.LABELS.BEST_SELLER}
              <img
                className='services_mobile__box--pack--label--vector'
                src={LeafWhite}
                alt='Sara Fig - Portugal Wedding Photography'
                style={{ transform: 'rotate(225deg)' }}
              />
            </div>
          </>
        )
      case props.pack === 'big':
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
            <div className='services_mobile__box--pack--label'>{label.LABELS.BIG_GUESTS}</div>
          </>
        )
      case props.pack === 'tribe':
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
            <div className='services_mobile__box--pack--label'>{label.LABELS.FAMILY_TRIBE}</div>
          </>
        )
      case props.pack === 'roots':
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
            <div className='services_mobile__box--pack--label'>{label.LABELS.FAMILY_ROOTS}</div>
          </>
        )
      default:
        return (
          <>
            <div className='services_mobile__box--pack--title services_mobile__box--pack--title--padding'>{props.title}</div>
          </>
        )
    }
  }

  return (
    <>
      <div className='services_mobile__box services_mobile__box--left'>
        <div className={`services_mobile__box--image services_mobile__box--image--${props.pack}`}></div>
        <div className={'services_mobile__box--pack'}>
          {showContent()}
          {longText}
        </div>
      </div>
    </>
  )
}
