import React, { useState } from 'react'
import { SidebarNav } from './SidebarNav'
import * as style from '../assets/style/styles'

export const Burger = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <style.StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div></div>
        <div></div>
        <div></div>
      </style.StyledBurger>
      <SidebarNav open={open} onClick={() => setOpen(!open)} />
    </>
  )
}
