import React from 'react'

export const DividerHome = () => {
  return (
    <div className='divider-home'>
      <hr className='divider-home--line' />
      <div className='wave-text'> LAUGHTER. TEARS. HAPPINESS </div>
      <hr className='divider-home--line' />
    </div>
  )
}
