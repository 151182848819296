import React from 'react'
import { LeafGreen } from '../assets/img'

export const NotFound = () => {
  return (
    <div className='pages-margin' style={{ height: '400px' }}>
      <div className='faq'>
        <div className='faq__header'>
          <img className='faq__header__vector faq__header__vector--left' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
          <div className='faq__header__title'>Oops! </div>
          <img className='faq__header__vector' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}>
          <div className='faq__section--item__question'>Page not found </div>
        </div>
        <div className='faq__section' style={{ marginTop: '4rem' }}>
          <div className='faq__section--item' style={{ textAlign: 'center' }}>
            <div className='faq__section--item__question'>Let's talk?</div>
            <div className='faq__section--item__answer'>Send me a message and let's discuss your ideas!</div>
            <div style={{ marginTop: '1rem' }}>
              <a href='/contact/'>
                <button className='app-btn'>Contact</button>
              </a>
            </div>
          </div>

          <div className='faq__section--item' style={{ textAlign: 'center' }}>
            <div className='faq__section--item__question'>Any doubts?</div>
            <div className='faq__section--item__answer'>
              Take a look at our frequently asked questions
              <div style={{ marginTop: '1rem' }}>
                <a href='/faq/'>
                  <button className='app-btn'>FAQ</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
