import React from 'react'
import { HomeLand1, HomeLand2, HomeLand3, HomeLand4, HomeLand5, HomeLand6, HomeLand7, HomeLand8, HomeLand9 } from '../assets/img'
import { Slideshow } from '../components/SlideShow'

const images = [HomeLand1, HomeLand2, HomeLand3, HomeLand4, HomeLand5, HomeLand6, HomeLand7, HomeLand8, HomeLand9]

export const HomeLandscape = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Slideshow images={images} />
    </div>
  )
}
