import React from 'react'
import { LeafBrown } from '../assets/img'
import { Link } from 'react-router-dom'

export const Faq = () => {
  return (
    <div className='pages-margin'>
      <div className='faq'>
        <div className='faq__header'>
          <img className='faq__header__vector faq__header__vector--left' src={LeafBrown} alt='Sara Fig - Portugal Wedding Photography' />
          <div className='faq__header__title'>FAQ</div>
          <img className='faq__header__vector' src={LeafBrown} alt='Sara Fig - Portugal Wedding Photography' />
        </div>
        <div className='faq__section'>
          <div className='faq__section--item'>
            <div className='faq__section--item__question'>How do you describe your style?</div>
            <div className='faq__section--item__answer'>
              I shoot in a documentary and cinematographic style, without interfering, to capture the day exactly as it unfolds.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>How long before the wedding should I book your services?</div>
            <div className='faq__section--item__answer'>As early as possible to secure your day with me. </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>Will we have the chance to meet before the day?</div>
            <div className='faq__section--item__answer'>Yes! This is very important to connect with my clients.</div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>Do you provide video service?</div>
            <div className='faq__section--item__answer'>
              I'm a photographer, but if you wish video service, I can recommend a few videographers with whom I team up on many weddings.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>How can we book the wedding date?</div>
            <div className='faq__section--item__answer'>
              After getting in touch with me and confirming the availability of your date, you simply need to make a payment of €200 as a
              booking deposit towards the total value of the package.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>Do you travel for work?</div>
            <div className='faq__section--item__answer'>
              I am available to photograph weddings anywhere! However, please note that travel and accommodation expenses may incur
              additional costs.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>Travel expenses are included?</div>
            <div className='faq__section--item__answer'>
              Yes, within the Algarve. Outside of this region, there may be an additional cost for travel.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>I want a wedding album. Do you make them?</div>
            <div className='faq__section--item__answer'>
              Yes. I also create personalized wedding albums. Depending on the size, the price starts at €200 and is an add-on to your
              package.
            </div>
          </div>
          <div className='faq__section--item'>
            <div className='faq__section--item__question'>How long until the delivery of the photos?</div>
            <div className='faq__section--item__answer'>
              For normal photoshoots the delivery time is 15 working days. For weddings the deadline can be up to 60 working days. There is
              a possibility of delivery in 5 days with an additional cost.
            </div>
          </div>

          <div className='faq__section--item'>
            <div className='faq__section--item__question'>Do you shoot other work?</div>
            <div className='faq__section--item__answer'>
              Yes, send me a message through{' '}
              <Link style={{ color: '#a48773', cursor: 'pointer' }} to='/contact'>
                contact form
              </Link>{' '}
              and let's talk about your ideas!{' '}
            </div>
          </div>
        </div>
        <div className='faq__footer'></div>
      </div>
    </div>
  )
}
