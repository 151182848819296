import moment from 'moment'

export const NAVBAR = {
  HOME: 'HOME',
  ABOUT: 'ABOUT',
  PORTFOLIO: 'PORTFOLIO',
  SERVICES: 'SERVICES',
  INFO: 'INFO',
  FAQ: 'FAQ',
  TESTIMONIALS: 'TESTIMONIALS',
  CONTACT: 'CONTACT ME',
  ALL: 'ALL',
  WEDDINGS: 'WEDDINGS',
  COUPLES: 'COUPLES',
  FAMILY: 'FAMILY',
  ENGAGEMENTS: 'ENGAGEMENTS',
}

export const NAVIGATION_PATH = {
  HOME: '/',
  ABOUT: '/about',
  PORTFOLIO: '/portfolio',
  SERVICES: '/services',
  FAQ: '/faq',
  TESTIMONIALS: '/testimonials',
  CONTACT: '/contact',
  WEDDINGS: '/portfolio/weddings',
  COUPLES: '/portfolio/couples',
  FAMILY: '/portfolio/family',
  SERVICES_WEDDINGS: '/services/weddings',
  SERVICES_ENGAGEMENTS: '/services/engagements',
  SERVICES_FAMILY: '/services/family',
}

export const LABELS = {
  BEST_SELLER: 'Best seller',
  INT_GUESTS: 'Up to 50 wedding guests',
  BIG_GUESTS: '+150 wedding guests',
  FAMILY_TRIBE: 'Up to 5 persons',
  FAMILY_ROOTS: 'Up to 10 persons',
  FOLLOW_WORK: 'Follow my work',
  INSTA_HANDLE: '@sarafigphoto',
  COPYRIGHT: `Copyright © ${moment().year()} Sara Fig. All rights reserved`,
}

export const POPUP_INFO_NEGATIVE_BALANCE = (date) =>
  `You have exceeded the available usage from your account. This amount will be charged in the next usage invoice date: ${date}.`

export const REGEX_VALID_EMAIL = /\S+@\S+\.\S+/

export const PROCESS = {
  PLANNING:
    "This is the first step in the process. It's the booking phase, when you make the down payment to secure the day, the phase in which all doubts are cleared and all ideas and details are discussed. I will make sure to answer any questions you have and provide clear and detailed information. You can count on me for everything you need to know.",
  SHOOT_DAY: `The day of the session/wedding arrived. I'll be with you the entire time and guide you during the session. You don't have to worry about anything! I'll tell you what to do and at the same time I'll be capturing spontaneous and natural images. I want you to relax and enjoy the moment as if I weren't there. `,
  EDITING:
    'Once the best photos have been chosen, edited according to my aesthetics and converted to high-resolution jpegs, they go to an online gallery platform where they will remain for the respective period of each pack. You will be able to download the photos for yourself and share with your friends and guests. The editing process can have several timelines: For photoshoots the timeline is 15 days, for weddings it can be up to 60 days after the wedding day.',
}
