import React from 'react'
import { HomeLandscape } from '../layouts/HomeLandscape'
import { HomeShow } from '../layouts/HomeShow'
import { HomeAbout } from '../layouts/HomeAbout'
import { HomePortfolio } from '../layouts/HomePortfolio'
import { DividerHome } from '../components/DividerHome'

export const Home = () => {
  return (
    <div className='pages-margin'>
      <HomeLandscape />
      <HomeAbout />
      <HomeShow />
      <DividerHome />
      <HomePortfolio />
    </div>
  )
}
