import React from 'react'

export const TestimonialsScreen = () => {
  return (
    <div className='pages-margin'>
      <div className='testimonials_screen'>
        <div className='testimonials_screen__box testimonials_screen__box--right'>
          <div className='testimonials_screen__box--image testimonials_screen__box--image--cd'></div>
          <div className='testimonials_screen__text'>
            <div className='testimonials_screen__text--title'>CLÁUDIA & DAN</div>
            <div className='testimonials_screen__text--description'>
              "Não há palavras para descrever o trabalho incrível da Sara. Desde o princípio até ao dia do nosso casamento a Sara foi sempre
              uma excelente profissional, atenciosa e prestável. <br /> O trabalho dela completou perfeitamente o nosso casamento, ficamos
              com umas fotos lindas do nosso dia tao especial, e antes do casamento fizemos um photo-shoot para o meu marido ficar mais
              confortável com a camera porque ele não é uma pessoa que adora tirar fotos, só aí deu para ver o quão professional a Sara é,
              ela ajudou a com que ele ficasse mais confortável a volta dela e da camera e não é que as fotos ficaram lindas? Fazer um
              photo-shoot antes do casamento ajuda bastante para que no big day estejamos mais relaxados com a nossa fotógrafa.
              <br /> No dia do casamento correu tudo muito bem, e a Sara tirou as fotos e editou elas como tínhamos falado e pedido, a sua
              atenção para os detalhes, a luz das fotos, as cores, a descontração tudo estava lá, e ainda nos oferecer uma caixa linda de
              madeira com fotos do nosso dia, Uau outra vez a Sara sempre a nos surpreender. <br />
              Mais uma vez agradecemos imenso do fundo do nosso coração a Sara por ter registado o nosso dia através de um click, obrigado
              por nos teres feito sentir tao confortáveis a tua volta e obrigado por tudo. Não hesitem a escolher a Sara para fotografar o
              vosso casamento, pois ela é a pessoa ideal para completar o vosso dia sem dúvida! Obrigado à melhor fotógrafa que conheço,
              Sara."
            </div>
          </div>
        </div>

        <div className='testimonials_screen__box testimonials_screen__box--left'>
          <div className='testimonials_screen__box--image testimonials_screen__box--image--left testimonials_screen__box--image--ed'></div>
          <div className='testimonials_screen__text testimonials_screen__text--left'>
            <div className='testimonials_screen__text--title'>ELISABETE & DIOGO</div>
            <div className='testimonials_screen__text--description'>
              "De todos os fornecedores, foste sem dúvida a chave mestre! Existe sentimentos que estão em fotografia. Momentos que sem eles
              eu nunca teria presenciado. Não existe valor que pague isso. <br />
              És a melhor sara, Obrigada."
            </div>
          </div>
        </div>

        <div className='testimonials_screen__box testimonials_screen__box--right'>
          <div className='testimonials_screen__box--image testimonials_screen__box--image--cr'></div>
          <div className='testimonials_screen__text'>
            <div className='testimonials_screen__text--title'>CÁTIA & RAFAEL</div>
            <div className='testimonials_screen__text--description'>
              "Adorámos o profissionalismo e o resultado do trabalho final.Foste incansavel e sempre pronta a nos ajudar em tudo o que era
              preciso!és uma excelente profissional e sempre precisarmos não hesitaremos em contratar os teus serviços novamente"
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
