import * as image from '../assets/img'

export const MenuItems = [
  { title: 'FAQ', path: '/faq' },
  { title: 'TESTIMONIALS', path: '/testimonials' },
]

export const PortfolioGallery = {
  wedding: [
    { alt: 'Wedding', src: image.PortfolioWedding1 },
    { alt: 'Wedding', src: image.PortfolioWedding2 },
    { alt: 'Wedding', src: image.PortfolioWedding3 },
    { alt: 'Wedding', src: image.PortfolioWedding4 },
    { alt: 'Wedding', src: image.PortfolioWedding5 },
    { alt: 'Wedding', src: image.PortfolioWedding6 },
    { alt: 'Wedding', src: image.PortfolioWedding7 },
    { alt: 'Wedding', src: image.PortfolioWedding10 },
    { alt: 'Wedding', src: image.PortfolioWedding12 },
    { alt: 'Wedding', src: image.PortfolioWedding13 },
    { alt: 'Wedding', src: image.PortfolioWedding15 },
    { alt: 'Wedding', src: image.PortfolioWedding17 },
    { alt: 'Wedding', src: image.PortfolioWedding19 },
    { alt: 'Wedding', src: image.PortfolioWedding20 },
    { alt: 'Wedding', src: image.PortfolioWedding21 },
    { alt: 'Wedding', src: image.PortfolioWedding22 },
    { alt: 'Wedding', src: image.PortfolioWedding23 },
    { alt: 'Wedding', src: image.PortfolioWedding24 },
    { alt: 'Wedding', src: image.PortfolioWedding25 },
    { alt: 'Wedding', src: image.PortfolioWedding26 },
    { alt: 'Wedding', src: image.PortfolioWedding27 },
    { alt: 'Wedding', src: image.PortfolioWedding28 },
    { alt: 'Wedding', src: image.PortfolioWedding30 },
    { alt: 'Wedding', src: image.PortfolioWedding31 },
    { alt: 'Wedding', src: image.PortfolioWedding32 },
    { alt: 'Wedding', src: image.PortfolioWedding33 },
    { alt: 'Wedding', src: image.PortfolioWedding34 },
    { alt: 'Wedding', src: image.PortfolioWedding35 },
    { alt: 'Wedding', src: image.PortfolioWedding37 },
    { alt: 'Wedding', src: image.PortfolioWedding38 },
    { alt: 'Wedding', src: image.PortfolioWedding41 },
    { alt: 'Wedding', src: image.PortfolioWedding42 },
    { alt: 'Wedding', src: image.PortfolioWedding43 },
    { alt: 'Wedding', src: image.PortfolioWedding44 },
    { alt: 'Wedding', src: image.PortfolioWedding47 },
    { alt: 'Wedding', src: image.PortfolioWedding48 },
    { alt: 'Wedding', src: image.PortfolioWedding50 },
    { alt: 'Wedding', src: image.PortfolioWedding51 },
    { alt: 'Wedding', src: image.PortfolioWedding52 },
    { alt: 'Wedding', src: image.PortfolioWedding53 },
    { alt: 'Wedding', src: image.PortfolioWedding54 },
    { alt: 'Wedding', src: image.PortfolioWedding55 },
    { alt: 'Wedding', src: image.PortfolioWedding56 },
    { alt: 'Wedding', src: image.PortfolioWedding57 },
    { alt: 'Wedding', src: image.PortfolioWedding58 },
    { alt: 'Wedding', src: image.PortfolioWedding59 },
    { alt: 'Wedding', src: image.PortfolioWedding60 },
    { alt: 'Wedding', src: image.PortfolioWedding61 },
    { alt: 'Wedding', src: image.PortfolioWedding62 },
    { alt: 'Wedding', src: image.PortfolioWedding63 },
    { alt: 'Wedding', src: image.PortfolioWedding64 },
    { alt: 'Wedding', src: image.PortfolioWedding65 },
    { alt: 'Wedding', src: image.PortfolioWedding66 },
    { alt: 'Wedding', src: image.PortfolioWedding67 },
    { alt: 'Wedding', src: image.PortfolioWedding68 },
    { alt: 'Wedding', src: image.PortfolioWedding69 },
    { alt: 'Wedding', src: image.PortfolioWedding70 },
    { alt: 'Wedding', src: image.PortfolioWedding71 },
    { alt: 'Wedding', src: image.PortfolioWedding72 },
    { alt: 'Wedding', src: image.PortfolioWedding73 },
    { alt: 'Wedding', src: image.PortfolioWedding74 },
    { alt: 'Wedding', src: image.PortfolioWedding75 },
    { alt: 'Wedding', src: image.PortfolioWedding76 },
    { alt: 'Wedding', src: image.PortfolioWedding77 },
    { alt: 'Wedding', src: image.PortfolioWedding78 },
    { alt: 'Wedding', src: image.PortfolioWedding79 },
    { alt: 'Wedding', src: image.PortfolioWedding80 },
    { alt: 'Wedding', src: image.PortfolioWedding81 },
    { alt: 'Wedding', src: image.PortfolioWedding82 },
    { alt: 'Wedding', src: image.PortfolioWedding84 },
    { alt: 'Wedding', src: image.PortfolioWedding83 },
    { alt: 'Wedding', src: image.PortfolioWedding87 },
    { alt: 'Wedding', src: image.PortfolioWedding85 },
    { alt: 'Wedding', src: image.PortfolioWedding86 },
    { alt: 'Wedding', src: image.PortfolioWedding88 },
    { alt: 'Wedding', src: image.PortfolioWedding89 },
  ],
  couples: [
    { alt: 'Couples 1', src: image.PortfolioCouples1 },
    { alt: 'Couples 2', src: image.PortfolioCouples2 },
    { alt: 'Couples 3', src: image.PortfolioCouples3 },
    { alt: 'Couples 4', src: image.PortfolioCouples4 },
    { alt: 'Couples 5', src: image.PortfolioCouples5 },
    { alt: 'Couples 6', src: image.PortfolioCouples6 },
    { alt: 'Couples 7', src: image.PortfolioCouples7 },
    { alt: 'Couples 8', src: image.PortfolioCouples8 },
    { alt: 'Couples 9', src: image.PortfolioCouples9 },
    { alt: 'Couples 10', src: image.PortfolioCouples10 },
    { alt: 'Couples 11', src: image.PortfolioCouples11 },
    { alt: 'Couples 12', src: image.PortfolioCouples12 },
    { alt: 'Couples 13', src: image.PortfolioCouples13 },
    { alt: 'Couples 14', src: image.PortfolioCouples14 },
    { alt: 'Couples 15', src: image.PortfolioCouples15 },
    { alt: 'Couples 16', src: image.PortfolioCouples16 },
    { alt: 'Couples 17', src: image.PortfolioCouples17 },
    { alt: 'Couples 18', src: image.PortfolioCouples18 },
  ],
  family: [
    { alt: 'Family', src: image.PortfolioFamily1 },
    { alt: 'Family', src: image.PortfolioFamily2 },
    { alt: 'Family', src: image.PortfolioFamily4 },
    { alt: 'Family', src: image.PortfolioFamily5 },
    { alt: 'Family', src: image.PortfolioFamily20 },
    { alt: 'Family', src: image.PortfolioFamily6 },
    { alt: 'Family', src: image.PortfolioFamily7 },
    { alt: 'Family', src: image.PortfolioFamily18 },
    { alt: 'Family', src: image.PortfolioFamily8 },
    { alt: 'Family', src: image.PortfolioFamily9 },
    { alt: 'Family', src: image.PortfolioFamily10 },
    { alt: 'Family', src: image.PortfolioFamily11 },
    { alt: 'Family', src: image.PortfolioFamily12 },
    { alt: 'Family', src: image.PortfolioFamily14 },
    { alt: 'Family', src: image.PortfolioFamily17 },
    { alt: 'Family', src: image.PortfolioFamily16 },
    { alt: 'Family', src: image.PortfolioFamily21 },
    { alt: 'Family', src: image.PortfolioFamily19 },
    { alt: 'Family', src: image.PortfolioFamily3 },
    { alt: 'Family', src: image.PortfolioFamily15 },
    { alt: 'Family', src: image.PortfolioFamily13 },
    { alt: 'Family', src: image.PortfolioFamily22 },
  ],
}
