import React from 'react'

export const HomeAbout = () => {
  return (
    <>
      <div className='home_about'>
        <div className='home_about__section'>
          <div className='home_about__section--card'>
            <div className='home_about__section--card__content'>
              <div className='home_about__section--card__content--title'>SARA FIG</div>
              <div className='home_about__section--card__content--subtitle'>A STORYTELLER PHOTOGRAPHER</div>
              <div className='home_about__section--card__content--description' style={{ fontStyle: 'italic' }}>
                "I'll capture the magic of your story with the exquisite beauty of candid moments, preserving them forever in timeless
                photographs."
              </div>
              <a href='/contact/'>
                <button className='app-btn'>Let's talk</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
