import React from 'react'
import { NavLink } from 'react-router-dom'
import * as label from '../constants/ui.labels.constants'
import * as style from '../assets/style/styles'

export const SidebarNav = ({ open, onClick }) => {
  const activeClass = 'navbar__options-item navbar__options-item--sidebar navbar__options-item--margin-right navbar--active'
  const inactiveClass = 'navbar__options-item navbar__options-item--sidebar navbar__options-item--margin-right navbar--inactive'

  const isActive = ({ isActive }) => (isActive ? activeClass : inactiveClass)

  return (
    <style.MenuList open={open}>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.HOME} end onClick={onClick}>
          {label.NAVBAR.HOME}
        </NavLink>
      </li>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.ABOUT} onClick={onClick}>
          {label.NAVBAR.ABOUT}
        </NavLink>
      </li>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.PORTFOLIO} onClick={onClick}>
          {label.NAVBAR.PORTFOLIO}
        </NavLink>
      </li>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.SERVICES} onClick={onClick}>
          {label.NAVBAR.SERVICES}
        </NavLink>
      </li>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.FAQ} onClick={onClick}>
          {label.NAVBAR.FAQ}
        </NavLink>
      </li>
      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.TESTIMONIALS} onClick={onClick}>
          {label.NAVBAR.TESTIMONIALS}
        </NavLink>
      </li>

      <li>
        <NavLink className={isActive} to={label.NAVIGATION_PATH.CONTACT} onClick={onClick}>
          {label.NAVBAR.CONTACT}
        </NavLink>
      </li>
    </style.MenuList>
  )
}
