import React, { useLayoutEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ReturnToTop } from './components/ReturnTopTop'
import { Home } from './pages/Home'

import { Services } from './pages/Services'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'
import { About } from './pages/About'
import { Portfolio } from './pages/Portfolio'
import { Contact } from './pages/Contact'
import { Faq } from './pages/Faq'
import { Testimonials } from './pages/Testimonials'
import { NotFound } from './layouts/NotFound'
import './css/main.css'

const App = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <ReturnToTop />
      <Wrapper>
        <Navbar />
        <Routes>
          <Route exact path='*' element={<NotFound />} />
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='portfolio' element={<Portfolio />} />
          <Route path='portfolio/weddings' element={<Portfolio />} />
          <Route path='portfolio/couples' element={<Portfolio />} />
          <Route path='portfolio/family' element={<Portfolio />} />
          <Route path='services' element={<Services />} />
          <Route path='contact' element={<Contact />} />
          <Route path='faq' element={<Faq />} />
          <Route path='testimonials' element={<Testimonials />} />
        </Routes>
        <Footer />
      </Wrapper>
    </div>
  )
}

export default App
