import React from 'react'
import { Divider } from '../components/Divider'
import * as label from '../constants/ui.labels.constants'

export const ProcessScreen = () => {
  return (
    <div className='process-show-screen'>
      <div className='process_screen'>
        <div className='process_screen__title'>PHOTOSHOOT Process</div>

        <div className='process_screen__number'>
          <div className='process_screen__number--item'>01</div>
          <Divider />
          <div className='process_screen__number--item'>02</div>
          <Divider />

          <div className='process_screen__number--item'>03</div>
        </div>
        <div className='process_screen__box'>
          <div className='process_screen__box--item'>
            <div className='process_screen__subtitle'>The Planning</div>
            <div className='process_screen__description'>{label.PROCESS.PLANNING} </div>
          </div>
          <div className='process_screen__box--item'>
            <div className='process_screen__subtitle'>Photoshoot Day</div>
            <div className='process_screen__description'>{label.PROCESS.SHOOT_DAY}</div>
          </div>
          <div className='process_screen__box--item'>
            <div className='process_screen__subtitle'>Editing Process</div>
            <div className='process_screen__description'>{label.PROCESS.EDITING}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
