import React, { useState, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import { InfoIcon } from '../assets/svg'
import { LeafGreen } from '../assets/img'
import * as label from '../constants/ui.labels.constants'

export const Contact = () => {
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [warningRequiredFields, setWarningRequiredFields] = useState(false)
  const [clickSubmit, setClickSubmit] = useState(false)
  const [isValidToSubmit, setIsValidToSubmit] = useState(false)
  const [service, setService] = useState([])
  const [aboutMe, setAboutMe] = useState([])
  const [guestPhotos, setGuestPhotos] = useState('')
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  const emailId = process.env.REACT_APP_EMAILJS_ID

  const [toSend, setToSend] = useState({
    from_name: '',
    partners_name: '',
    message: '',
    email: '',
    phone_number: '',
    desired_date: '',
  })

  const isValidEmail = (email) => label.REGEX_VALID_EMAIL.test(email)

  const handlerChangeValues = (e) => {
    setWarningRequiredFields(false)
    setToSend({ ...toSend, [e.target.name]: e.target.value })
  }

  const handlerChangePhoneNumber = (e) => {
    const { name, value } = e.target
    const formattedValue = value.startsWith('+') ? '+' + value.slice(1).replace(/\D/g, '') : value.replace('+', '')
    setWarningRequiredFields(false)
    setToSend({ ...toSend, [name]: formattedValue })
  }

  const onHandleService = (e) => {
    setWarningRequiredFields(false)
    setService((prev) => (service.includes(e.target.value) ? prev.filter((cur) => cur !== e.target.value) : [...prev, e.target.value]))
  }
  const onHandleAboutMe = (e) =>
    setAboutMe((prev) => (aboutMe.includes(e.target.value) ? prev.filter((cur) => cur !== e.target.value) : [...prev, e.target.value]))

  const handleKeyDown = (e) => {
    if (e.target.value.length >= 15 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  }

  useEffect(() => {
    const validSubmitDetails =
      toSend.from_name.length &&
      toSend.email.length &&
      toSend.message.length &&
      toSend.desired_date.length &&
      service.length &&
      isValidEmail(toSend.email)
    if (service.includes('Wedding') && !guestPhotos.length) setIsValidToSubmit(false)
    else if (validSubmitDetails) {
      setIsValidToSubmit(true)
      setWarningRequiredFields(false)
    } else setIsValidToSubmit(false)
  }, [
    guestPhotos.length,
    service,
    service.length,
    toSend.desired_date.length,
    toSend.email,
    toSend.email.length,
    toSend.from_name.length,
    toSend.message.length,
    toSend.phone_number,
  ])

  const onSubmit = (e) => {
    e.preventDefault()
    setClickSubmit(true)
    setIsLoading(true)

    const data = {
      ...toSend,
      service: service.join(', '),
      about_me: aboutMe.join(', '),
      guest_photos: !service.includes('Wedding') ? '' : guestPhotos,
    }

    if (isValidToSubmit) {
      emailjs
        .send(serviceId, templateId, data, emailId)
        .then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setIsLoading(false)
          setShowModalSuccess(true)
        })
        .catch((err) => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setIsLoading(false)
          setShowModalError(true)
        })
    } else {
      setIsLoading(false)
      setWarningRequiredFields(true)
    }
  }

  const checkValidationEmail = () => {
    if (clickSubmit && !toSend.email.length) {
      return (
        <label className='label-red label-red--field'>
          <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
          This field is required
        </label>
      )
    } else if (clickSubmit && toSend.email.length && !isValidEmail(toSend.email)) {
      return (
        <label className='label-red label-red--field'>
          <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
          Please enter a valid email address
        </label>
      )
    }
  }

  const checkWarning = (field) => clickSubmit && !field.length

  const contactContent = () => (
    <>
      <div className='contact__title'>LET'S GO TOGETHER IN THIS ADVENTURE</div>
      <div className='contact__section--image-mobile'></div>
      <div className='contact__section'>
        <div className='contact__section--pictures'>
          <div className='contact__section--pictures--1'></div>
          <div className='contact__section--pictures--2'></div>
          <div className='contact__section--pictures--3'></div>
          <div className='contact__section--pictures--4'></div>
          <div className='contact__section--pictures--5'></div>
        </div>
        <div className='contact__section--form'>
          <div className='contact__section--form--grid'>
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex '>
              <label>
                Your name
                <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
              </label>
              <input required className='line-input' type='text' name='from_name' value={toSend.from_name} onChange={handlerChangeValues} />
              {checkWarning(toSend.from_name) ? (
                <label className='label-red label-red--field'>
                  <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                  This field is required
                </label>
              ) : null}
            </div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
              Your partner's name
              <input className='line-input' name='partners_name' type='text' value={toSend.partners_name} onChange={handlerChangeValues} />
            </div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
              Phone number
              <input
                className='line-input'
                inputMode='numeric'
                name='phone_number'
                value={toSend.phone_number}
                onChange={handlerChangePhoneNumber}
                maxLength={15}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
              <label>
                E-mail <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
              </label>
              <input required className='line-input' type='email' name='email' value={toSend.email} onChange={handlerChangeValues} />
              {checkValidationEmail()}
            </div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label__options'>
              <label>
                What type of service are you looking for?
                <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
              </label>
              {checkWarning(service) ? (
                <label className='label-red label-red--field'>
                  <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                  This field is required
                </label>
              ) : null}

              <div className='contact__section--form--grid--label__checkboxes'>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input
                    className='checkbox-input'
                    type='checkbox'
                    id='wedding'
                    name='service'
                    value={'Wedding'}
                    onClick={onHandleService}
                  />
                  <label>Wedding</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input type='checkbox' id='engagements' name='service' value={'Engagements'} onClick={onHandleService} />
                  <label>Engagement photoshoots</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input type='checkbox' id='proposal' name='service' value={'Proposal'} onClick={onHandleService} />
                  <label>Proposal</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input type='checkbox' id='family' name='service' value={'Family'} onClick={onHandleService} />
                  <label>Family</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options contact__section--form--grid--label__checkboxes--options--top-padding'>
                  <input type='checkbox' id='other' name='service' value={'Other work'} onClick={onHandleService} />
                  <label>Other work</label>
                </div>
                <span className='label-description label-description--with-padding'>Specify in the following message box</span>
              </div>
            </div>

            {service.includes('Wedding') ? (
              <div className='contact__section--form--grid--label contact__section--form--grid--label__options'>
                <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
                  <label>
                    Guest photos? <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
                  </label>
                  <span className='label-description '>Would you like to have the traditional photoshoot with your guests?</span>
                  {clickSubmit && service.includes('Wedding') && !guestPhotos.length ? (
                    <label className='label-red label-red--field'>
                      <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                      This field is required
                    </label>
                  ) : null}
                  <div className='contact__section--form--grid--label__checkboxes'>
                    <div className='contact__section--form--grid--label__checkboxes--options'>
                      <input
                        type='radio'
                        name='guest_photos'
                        id='yes'
                        value={'Yes'}
                        onChange={(e) => setGuestPhotos(e.target.value)}
                        checked={guestPhotos === 'Yes'}
                      />
                      <label>Yes</label>
                    </div>
                    <div className='contact__section--form--grid--label__checkboxes--options'>
                      <input
                        type='radio'
                        name='guest_photos'
                        id='no'
                        value={'No'}
                        onChange={(e) => setGuestPhotos(e.target.value)}
                        checked={guestPhotos === 'No'}
                      />
                      <label>No</label>
                    </div>
                    <div className='contact__section--form--grid--label__checkboxes--options'>
                      <input
                        type='radio'
                        name='guest_photos'
                        id='not-decided'
                        value={'Not decided yet'}
                        onChange={(e) => setGuestPhotos(e.target.value)}
                        checked={guestPhotos === 'Not decided yet'}
                      />
                      <label>Not decided yet</label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='empty-div'></div>
            )}
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
              <label>
                Tell me everything about your session day!
                <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
              </label>
              {clickSubmit && !toSend.message.length ? (
                <label className='label-red label-red--field'>
                  <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                  This field is required
                </label>
              ) : null}
              <textarea
                required
                name='message'
                value={toSend.message}
                onChange={handlerChangeValues}
                rows='8'
                cols='33'
                placeholder='(e.g.) When did you meet? What type of ceremony and type of photography you identify the most? What do you like about my work?...'></textarea>
            </div>
            <div className='empty-div'></div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label--flex'>
              <label>
                Desired date <span className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>*</span>
              </label>
              <span className='label-description'>A specific date or a flexible date</span>
              {clickSubmit && !toSend.desired_date.length ? (
                <label className='label-red label-red--field'>
                  <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                  This field is required
                </label>
              ) : null}
              <input className='line-input' type='text' name='desired_date' value={toSend.desired_date} onChange={handlerChangeValues} />
            </div>
            <div className='empty-div'></div>
            <div className='contact__section--form--grid--label contact__section--form--grid--label__options'>
              <label>How did you hear about my work?</label>

              <div className='contact__section--form--grid--label__checkboxes'>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input
                    className='checkbox-input'
                    type='checkbox'
                    name='about_me'
                    id='instagram'
                    value={'Instagram'}
                    onClick={onHandleAboutMe}
                  />
                  <label>Instagram</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input
                    className='checkbox-input'
                    type='checkbox'
                    name='about_me'
                    id='google'
                    value={'Google search'}
                    onClick={onHandleAboutMe}
                  />
                  <label>Google search</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input
                    className='checkbox-input'
                    type='checkbox'
                    name='about_me'
                    id='facebook'
                    value={'Facebook'}
                    onClick={onHandleAboutMe}
                  />
                  <label>Facebook</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options'>
                  <input className='checkbox-input' type='checkbox' id='friend' value={'Friend'} onClick={onHandleAboutMe} />
                  <label>From a friend</label>
                </div>
                <div className='contact__section--form--grid--label__checkboxes--options contact__section--form--grid--label__checkboxes--options--top-padding'>
                  <input type='checkbox' id='other' value={'Other'} onClick={onHandleAboutMe} />
                  <label>Other</label>
                </div>
              </div>
            </div>

            <div className='empty-div'></div>
            <div>
              <div className='contact__section--actions--label' style={{ fontSize: '1rem !important' }}>
                <label>Any doubts?</label>
              </div>
              <div className='contact__section--actions--faq'>
                <a href='/faq/'>
                  <span className='faq-btn'>FAQ</span>
                </a>
              </div>
            </div>
            <div className='contact__section--actions'>
              <div>
                <div>
                  <button className='app-btn' onClick={onSubmit} disabled={isLoading}>
                    Submit
                  </button>
                </div>
                {clickSubmit && warningRequiredFields && (
                  <label className='label-red'>
                    <img src={InfoIcon} alt='Sara Fig - Portugal Wedding Photography' height='11px' style={{ paddingRight: '.5rem' }} />
                    Please fill in the required fields
                    <span
                      style={{ paddingLeft: '0.2rem', color: '#d95c5c' }}
                      className='contact__section--form--grid--label  contact__section--form--grid--label--sym'>
                      *
                    </span>
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const showStates = () => {
    switch (true) {
      case isLoading:
        return (
          <div className='loader-window'>
            <span className='loader'></span>
          </div>
        )
      case showModalSuccess:
        return (
          <div id='open-modal' className='modal-window'>
            <div className='modal-content'>
              <div className='modal-content__title'>
                <img className='modal__icon' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
                THANK YOU! <img className='modal__icon' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
              </div>
              <div className='modal-content__description-modal'>
                Your message has been received. <br />
                I'll get back to you as soon as I can.
              </div>
              <div className='modal-content__description-modal'>
                While you wait, visit my social media
                <div className='contact__section--actions--faq'>
                  <a href='https://www.instagram.com/sarafigphoto/'>
                    <span className='faq-btn'>@sarafigphoto</span>
                  </a>
                </div>
              </div>
              <button className='app-btn' style={{ marginTop: '2rem' }} onClick={() => setShowModalSuccess(false)}>
                OK
              </button>
            </div>
          </div>
        )
      case showModalError:
        return (
          <div id='open-modal' className='modal-window'>
            <div className='modal-content'>
              <div className='modal-content__title'>
                <img className='modal__icon' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
                OOPS! <img className='modal__icon' src={LeafGreen} alt='Sara Fig - Portugal Wedding Photography' />
              </div>
              <div className='modal-content__description-modal'>
                Something went wrong. <br />
                Please try again, or send your contact directly to <label style={{ fontWeight: '400' }}>info.sarafig@gmail.com</label>.{' '}
                <br />
                Thank you
              </div>
              <button className='app-btn app-btn--close' onClick={() => setShowModalError(false)}>
                CLOSE
              </button>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='pages-margin'>
      <div className='contact'>
        {showStates()} {contactContent()}
      </div>
    </div>
  )
}
