import React from 'react'
import { LeafBrown } from '../assets/img'

export const About = () => {
  return (
    <div className='pages-margin'>
      <div className='about'>
        <div className='about__first-section'></div>
        <div className='about__second-section'>
          <div className='about__second-section__title'>NICE TO MEET YOU</div>
          <div className='about__second-section__subtitle'> HI, I'M SARA!</div>
          <div className='about__second-section__text about__second-section__text--padding'>
            I'm a wedding photographer and I live in Lagos, Algarve. I am graduated in Photography and Visual Culture and in 2016 I started
            working on a wedding photography studio as a wedding photographer.
          </div>
          <div className='about__second-section__text about__second-section__text--padding'>
            I could tell you a lot more about my journey but what I really want to tell is your story through my vision.
          </div>
          <div
            className='about__second-section__text about__second-section__text--padding about__second-section__text--color'
            style={{ fontStyle: 'italic' }}>
            "To me, photography it is more than a job. It's being able to do what I like the best and to create eternal memories."
          </div>
        </div>
      </div>
      <div className='about about--second'>
        <div className='about__second-section'>
          <div className='about__second-section__title about__second-section__title--2 '>LAUGHTER. TEARS. HAPPINESS</div>
          <div className='about__second-section__text about__second-section__text--padding about__second-section__text--2'>
            - The real moments that a wedding is made of - <br /> Capturing the essence of your wedding day in photographs that bring back
            the same emotions, allowing you to relive those special moments
          </div>
          <div className='about__second-section__button'>
            <a href='/contact/'>
              <button className='app-btn'>Let's talk</button>
            </a>
          </div>
          <img className='about__second-section__vector' src={LeafBrown} alt='Sara Fig - Portugal Wedding Photography' />
        </div>

        <div className='about--second__image'></div>
      </div>
    </div>
  )
}
