import React, { useState, useEffect, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { PortfolioGallery } from '../constants/constants'
import * as label from '../constants/ui.labels.constants'

export const Portfolio = () => {
  const pathname = useLocation().pathname
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)

  const activeClass = 'navbar__options-item navbar__options-item--margin-right navbar--active'
  const inactiveClass = 'navbar__options-item navbar__options-item--margin-right navbar--inactive'
  const isActive = ({ isActive }) => (isActive ? activeClass : inactiveClass)

  const openSlideshow = (index) => setSelectedImageIndex(index)
  const closeSlideshow = () => setSelectedImageIndex(null)

  const handleSlideshowNext = useCallback((direction = 'next') => {
    const lastIndex = PortfolioGallery.length - 1
    setSelectedImageIndex((prevIndex) => {
      if (direction === 'next') return prevIndex === lastIndex ? 0 : prevIndex + 1
      else return prevIndex === 0 ? lastIndex : prevIndex - 1
    })
  }, [])
  const handleSlideshowPrev = useCallback(() => {
    handleSlideshowNext('prev')
  }, [handleSlideshowNext])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') handleSlideshowPrev()
      else if (e.key === 'ArrowRight') handleSlideshowNext()
      else if (e.key === 'Escape') closeSlideshow()
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleSlideshowNext, handleSlideshowPrev])

  const showContent = () => {
    const weddingPath = ['/portfolio', '/portfolio/weddings']
    const couplesPath = ['/portfolio/couples']
    const familyPath = ['/portfolio/family']

    const category = getCategoryFromPath(pathname)
    const galleryImages = PortfolioGallery[category] || []

    if (weddingPath.includes(pathname) || couplesPath.includes(pathname) || familyPath.includes(pathname)) {
      return (
        <div className='p-container'>
          <MasonryImageGallery openSlideshow={openSlideshow} category={category} />

          {selectedImageIndex !== null && selectedImageIndex < galleryImages.length && (
            <div className='slideshow-portfolio'>
              <div className='slideshow-portfolio-overlay' onClick={() => closeSlideshow()} />
              <div className='arrows-portfolio'>
                <div className='arrows-portfolio--arrow' onClick={() => handleSlideshowPrev()}>
                  <div className='long-arrow-left-portfolio'></div>
                </div>
                <div className='arrows-portfolio--arrow' onClick={() => handleSlideshowNext()}>
                  <div className='long-arrow-right-portfolio'></div>
                </div>
              </div>
              <div className='slideshow-portfolio-content'>
                <img
                  src={galleryImages[selectedImageIndex].src}
                  alt={galleryImages[selectedImageIndex].alt}
                  className='slideshow-portfolio-image'
                />
              </div>
              <button className='slideshow-portfolio-close' onClick={() => closeSlideshow()}>
                &times;
              </button>
            </div>
          )}
        </div>
      )
    }
  }

  const getCategoryFromPath = (path) => {
    if (path.includes('/portfolio/weddings')) {
      return 'wedding'
    } else if (path.includes('/portfolio/couples')) {
      return 'couples'
    } else if (path.includes('/portfolio/family')) {
      return 'family'
    } else {
      return 'wedding'
    }
  }

  return (
    <div className='pages-margin'>
      <div className='portfolio_screen'>
        <div className='portfolio_screen__header'>
          <ul className='portfolio_screen__header__sections'>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive} to={label.NAVIGATION_PATH.WEDDINGS || label.NAVIGATION_PATH.PORTFOLIO}>
                {label.NAVBAR.WEDDINGS}
              </NavLink>
            </li>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive} to={label.NAVIGATION_PATH.COUPLES}>
                {label.NAVBAR.COUPLES}
              </NavLink>
            </li>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive} to={label.NAVIGATION_PATH.FAMILY}>
                {label.NAVBAR.FAMILY}
              </NavLink>
            </li>
          </ul>
        </div>

        {showContent()}
      </div>
    </div>
  )
}

export const MasonryImageGallery = ({ category, openSlideshow }) => {
  const images = PortfolioGallery[category] || []

  return (
    <div className='images-container'>
      <div className='masonry_grid'>
        {!images || images.length === 0 ? (
          <h4>
            Oops! Can't load the portfolio right now.
            <br /> Please, refresh the page or send me a message through the{' '}
            <a href='/contact/' style={{ textDecoration: 'underline' }}>
              contact form
            </a>
            .
          </h4>
        ) : (
          images.map((item, index) => (
            <div key={`Sara-Fig_${index}`} className='item' onClick={() => openSlideshow(index)}>
              <img src={item.src} alt='Sara Fig Photography' />
              <div className='img-overlay'>
                <i className='fa fa-plus-circle' aria-hidden='true'></i>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
