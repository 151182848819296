import React, { useEffect, useRef, useState, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import * as label from '../constants/ui.labels.constants'
import { ServicesScreen } from '../layouts/ServicesScreen'
import { ServicesMobile } from '../layouts/ServicesMobile'
import { ProcessScreen } from '../containers/ProcessScreen'
import { ProcessMobile } from '../containers/ProcessMobile'

export const Services = (refs) => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const activeClass = 'navbar__options-item navbar__options-item--margin-right navbar--active'
  const inactiveClass = 'navbar__options-item navbar__options-item--margin-right navbar--inactive'
  // const isActive = (props) => (props.isActive ? activeClass : inactiveClass)
  const isActive = (to) => {
    return location.hash === to ? activeClass : inactiveClass
  }
  const defaultSectionRef = useRef('')
  const weddingsSectionRef = useRef(null)
  const engagementsSectionRef = useRef(null)
  const familySectionRef = useRef(null)
  const weddingsSectionRefMobile = useRef(null)
  const engagementsSectionRefMobile = useRef(null)
  const familySectionRefMobile = useRef(null)

  const scrollSmoothHandler = useCallback(
    (ref) => {
      if (!loading && ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [loading],
  )

  useEffect(() => {
    setLoading(false)
  }, [])
  useEffect(() => {
    setLoading(false)
    switch (location.hash) {
      case '#':
        scrollSmoothHandler(defaultSectionRef)
        break
      case '#weddings':
        scrollSmoothHandler(weddingsSectionRef) || scrollSmoothHandler(weddingsSectionRefMobile)
        break
      case '#engagements':
        scrollSmoothHandler(engagementsSectionRef) || scrollSmoothHandler(engagementsSectionRefMobile)
        break
      case '#family':
        scrollSmoothHandler(familySectionRef) || scrollSmoothHandler(familySectionRefMobile)
        break
      default:
      // ignore
    }
  }, [location.hash, location, scrollSmoothHandler])

  return (
    <>
      <div className='pages-margin'>
        <div className='portfolio_screen__header'>
          <ul className='portfolio_screen__header__sections'>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive('#weddings')} to={'#weddings'} onClick={() => scrollSmoothHandler(weddingsSectionRef)}>
                {label.NAVBAR.WEDDINGS}
              </NavLink>
            </li>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive('#engagements')} to={'#engagements'} onClick={() => scrollSmoothHandler(engagementsSectionRef)}>
                {label.NAVBAR.ENGAGEMENTS}
              </NavLink>
            </li>
            <li className='portfolio_screen__header__sections--item'>
              <NavLink className={isActive('#family')} to={'#family'} onClick={() => scrollSmoothHandler(familySectionRef)}>
                {label.NAVBAR.FAMILY}
              </NavLink>
            </li>
          </ul>
        </div>

        <ServicesScreen
          weddingsSectionRef={weddingsSectionRef}
          engagementsSectionRef={engagementsSectionRef}
          familySectionRef={familySectionRef}
        />
        <ServicesMobile
          weddingsSectionRef={weddingsSectionRefMobile}
          engagementsSectionRef={engagementsSectionRefMobile}
          familySectionRef={familySectionRefMobile}
        />
      </div>
      <ProcessScreen />
      <ProcessMobile />
      <div className='other_work'>
        <div className='other_work--title'>For other work, send a message with your ideas</div>

        <div className='other_work--image'></div>
        <a href='/contact/'>
          <button className='app-btn'>Let's talk</button>
        </a>
      </div>
    </>
  )
}
