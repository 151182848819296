import React from 'react'
import { LeafWhite } from '../assets/img'

export const Divider = () => {
  return (
    <div className='divider'>
      <hr className='divider--line' />
      <img className='divider--image' src={LeafWhite} alt='Sara Fig - Portugal Wedding Photography' />
      <hr className='divider--line' />
    </div>
  )
}
