import React from 'react'

export const PricingCard = (props) => {
  const hours = props.time === 1 ? 'hour ' : 'hours '
  return (
    <>
      <div className='pricing__card'>
        <div className='pricing__card--category'>{props.category}</div>

        {props.withPricing ? (
          <div className='services_screen__box--pack--list services_screen__box--pack--list--center'>
            <ul>
              <li className='services_screen__box--pack--list--item'>
                <span className='services_screen__box--pack--list--item__number'>1</span> Photographer
              </li>
              <li className='services_screen__box--pack--list--item'>
                <span className='services_screen__box--pack--list--item__number'>{props.time} </span>
                {hours}
                {props.coverage}
              </li>
              <li className='services_screen__box--pack--list--item'>
                Approx <span className='services_screen__box--pack--list--item__number'>{props.quantity} </span> High Quality images
              </li>
            </ul>
          </div>
        ) : (
          ''
        )}

        <div className='pricing__card--price'>
          Starting at{' '}
          <span className='pricing__card--price--total'>
            {props.pricing}€<span className='special-character'>*</span>
          </span>
        </div>

        {props.includeAllPackagesInfo && (
          <div className='pricing__card--include'>
            <span className='special-character'>*</span>All packages include
            <span className='pricing__card--include--text'>
              {' '}
              - {props.hasPineBox && 'Pine box with pen drive and '} {props.prints} prints in size 10x15
            </span>
          </div>
        )}
        <li
          className='services_screen__box--pack--list--item services_screen__box--pack--list--item--ps'
          style={{ paddingTop: props.includeAllPackagesInfo ? '1rem' : '0rem' }}>
          Final edited digital images delivered via online gallery
        </li>
        <a href='/contact/'>
          <button className='app-btn-services'>Let's talk</button>
        </a>
      </div>
    </>
  )
}
